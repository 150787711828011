import React, { useState } from "react"
import { Link, navigate } from "gatsby"
import styled, { keyframes } from "styled-components"
import typography from "../utils/typography"

import Layout, { StyledLink, DetailSection } from "../components/layout"
import SEO from "../components/seo"

import { HeroWrapper, HeroHeading } from "../components/hero/heroTitle"
import Reveal from "../components/reveal"
import CheckGuestForm from "../components/forms/checkEmailForm"

const Hero = styled.div`
  display: grid;
  min-height: 20vh;
  grid-column: main / gutter-right;
  align-content: flex-end;

  @media screen and (min-width: 65rem) {
    min-height: 19.5rem;
  }
`

const Content = styled.div`
  max-width: 17.75rem;
  margin-top: 2.5rem;
  margin-left: auto;
  font-size: .875rem;

  @media screen and (min-width: 65rem) {
    max-width: 24.5rem;
    margin-top: 6.5rem;
    font-size: 1rem;
  }
`

const Offset = styled.div`
  display: grid;
  grid-column: 2 / -1; /* start position from DetailSection */

  @media screen and (min-width: 46rem) {
    grid-column: 3 / -1; /* start position from DetailSection */
  }

  @media screen and (min-width: 56rem) {
    grid-column: 4 / -1; /* start position from DetailSection */
  }
`

const P = styled.p`
  margin-bottom: 1.5rem;
`

const StrongText = styled.strong`
  font-weight: 600;
`

const StyledForm = styled.div`
  label {
    font-size: 1rem;
  }
`

const GuestNotFound = ({ location }) => {
  const cancel = () => {
    navigate('/', { state: {} })
  }

  const showConfirmation = () => {
    navigate('/guest-found', {
      state: {}
    })
  }

  return (
    <Layout>
      {/* <SEO title="Page two" /> */}
      <Hero>
        <HeroWrapper>
          <HeroHeading>Oh no.</HeroHeading>
        </HeroWrapper>
      </Hero>

      <DetailSection>
        <Offset>
          <Reveal delay="2.15s">
            <Content>
              <P>Sorry, we can’t find {location.state ? (
                <>
                  the email <StrongText>{location.state.email}</StrongText>
                </>
              )
                : 'that email'}.</P>
              <P>If the problem persists or you think there is a mistake, please email us.</P>
              <P>
                <StyledLink to="/">go back</StyledLink>, or try again.
            </P>
              <StyledForm>
                <CheckGuestForm handleCancelForm={() => cancel()} handleSuccess={() => showConfirmation()}></CheckGuestForm>
              </StyledForm>
            </Content>
          </Reveal>
        </Offset>
      </DetailSection>

      {/* <StyledLink to="/">Go back </StyledLink> */}
    </Layout>
  )
}

export default GuestNotFound
