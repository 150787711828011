import React, { useState } from "react"
import { navigate, Link, navigateTo } from "gatsby"
import styled, { ThemeProps, ThemedStyledProps } from "styled-components"
import BasicButton from "../buttons/basicButton"
import GhostButton from "../buttons/ghostButton"
import TextInput from "../textInput"
import axios from "axios"

const CheckGuestForm = ({ handleCancelForm, handleSuccess }) => {
  const [email, setEmail] = useState("")
  // const [emailSent, setEmailSent] = useState(false);

  const handleEmailChange = (event: any) => {
    setEmail(event.target.value)
  }

  const handleSubmit = event => {
    event.preventDefault()
  }

  const handleCancel = () => {
    setEmail("")
    handleCancelForm() // custom cancel behavior from parent
  }

  const submitGuest = email => {
    axios
      .put("/.netlify/functions/rsvpSeen", { email })
      .then(({ data }) => {
        console.log(data)
        handleSuccess(email)
        // navigate('/rsvp', {
        //   state: { data }
        // })
        // setEmailSent(true);
      })
      .catch(err => {
        navigate("/guest-not-found", {
          state: { email },
        })
      })
  }

  return (
    <form onSubmit={handleSubmit}>
      <TextInput
        type="email"
        name="guest-email"
        label="Your email"
        value={email}
        onChange={() => handleEmailChange(event)}
      />
      <br />
      <BasicButton handleClick={() => submitGuest(email)}>Submit</BasicButton>
      <GhostButton handleClick={() => handleCancel()}>cancel</GhostButton>
    </form>
  )
}

export default CheckGuestForm
