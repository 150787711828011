import React from "react"
import styled, { ThemeProps, ThemedStyledProps } from "styled-components"
import typography from "../utils/typography"
import { textIn } from "./reveal"

const inter = typography.options.bodyFontFamily.join()

const Input = styled.input`
  max-width: 100%;
  padding: 0.625rem 0.75rem;
  margin: 0 0 1.5rem;
  font-size: 1rem;
  border: 0;
  background-color: ${props => props.theme.colors.formBackground};
  color: ${props => props.theme.colors.formColor};
  box-shadow: inset 0 -2px;
`

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-family: ${inter};
  font-size: 0.875rem;
`

interface TextInputProps {
  type: string
  name: string
  label: string
  value: string
  onChange: (event: React.FormEvent<HTMLInputElement>) => void
  children?: any
  useFormik?: boolean
}

const TextInput: React.FC<TextInputProps> = ({
  type,
  name,
  label,
  value,
  onChange,
  children,
  useFormik = false,
}) => {
  if (useFormik) {
  }

  return (
    <>
      <Label htmlFor={name}>
        {label}
        {children}
      </Label>
      <Input type={type} id={name} value={value} onChange={onChange} />
    </>
  )
}

export default TextInput
