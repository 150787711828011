import React from "react"
import styled, { ThemeProps, ThemedStyledProps } from "styled-components"
import typography from "../../utils/typography"
import { textIn } from "../reveal"

const inter = typography.options.bodyFontFamily.join();

const Button = styled.button`
  padding: .375rem .75rem;
  margin: 0;
  margin-right: .75rem;
  font-size: .875rem;
  line-height: calc(18/14);
  background-color: transparent;
  border: 1px solid transparent;
  color: ${props => props.theme.colors.primaryColor};
  transition: 300ms ease-in-out background-color, 250ms ease-in box-shadow;
  cursor: pointer;
  box-shadow: 0 0 ${props => props.theme.colors.background};

  &:hover {
    background-color: ${props => props.theme.colors.formBackground};
    box-shadow: 2px 2px ${props => props.theme.colors.background}, 3px 3px ${props => props.theme.colors.primaryColor};
  }

  &:active {
    background-color: ${props => props.theme.colors.formBackground};
    box-shadow: 0 0;
    transform: translate3d(2px, 2px, 0);
  }
`

const GhostButton = ({ handleClick, children }) => (
  <Button onClick={handleClick}>
    {children}
  </Button>
)

export default GhostButton
